import { Chart, BarController, LineController, BarElement, LineElement, PointElement, LinearScale, CategoryScale, TimeScale, Title, Filler, Legend, Tooltip } from 'chart.js'
import 'chartjs-adapter-date-fns'

class CustomLine extends LineController {
    draw() {
        super.draw(arguments)
        const ctx = this.chart.ctx

        if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
            const x = this.chart.tooltip._active[0].element.x
            const topY = this.chart.legend.bottom
            const bottomY = this.chart.chartArea.bottom

            ctx.save()
            ctx.beginPath()
            ctx.moveTo(x, topY)
            ctx.lineTo(x, bottomY)
            ctx.lineWidth = 1
            ctx.strokeStyle = 'rgba(255, 99, 132, 1)'
            ctx.stroke()
            ctx.restore()
        }
    }
}

CustomLine.id = 'customLine'
CustomLine.defaults = LineController.defaults
Chart.register(BarController, LineController, BarElement, LineElement, PointElement, CustomLine, LinearScale, CategoryScale, TimeScale, Filler, Legend, Tooltip, Title)

const drawBarChart = async (ctx, barData, images, borderColour, bgColour) => {
    const { labels, data } = barData
    
    const barChart = new Chart(ctx, {
        type: 'bar',
        data: {
            labels: labels,
            datasets: [{
                data: data,
                backgroundColor: bgColour || 'rgba(255, 99, 132, 0.2)',
                borderColor: borderColour || 'rgba(255, 99, 132, 1)',
                borderWidth: 1
            }]
        },
        plugins: [{
            afterDraw: chart => {
                const ctx = chart.ctx
                const xAxis = chart.scales['x']
                const yAxis = chart.scales['y']
                ctx.font = `bold ${Chart.defaults.font.size}px ${Chart.defaults.font.family}`
                ctx.fillStyle = Chart.defaults.color
                xAxis.ticks.forEach((value, index) => {
                    const x = xAxis.getPixelForTick(index)
                    const image = new Image()
                    image.src = images[index].image
                    ctx.fillText(value.label, x - 18, yAxis.bottom + 18)
                    ctx.drawImage(image, x - 20, yAxis.bottom + 27, 35, 35)
                })
            }
        }],
        options: {
            scales: {
                x: {
                    ticks: {
                        padding: 10,
                        color: '#fff'
                    },
                    grid: {
                        display: true,
                        lineWidth: 1,
                        zeroLineWidth: 1,
                        zeroLineColor: 'rgba(0, 0, 0, 0.1)'
                    }
                },
                y: {
                    display: false,
                    ticks: {
                        beginAtZero: true
                    },
                    grid: {
                        display: false
                    }
                }
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: false
                },
                title: {
                    display: true,
                    text: ''
                },
            },
            layout: {
                padding: {
                    bottom: 20
                }
            },
            responsive: true
        }
    })

    return barChart
}

const drawLineChart = async (ctx, lineData, images, borderColour) => {
    const { labels, data } = lineData
    const imageArr = images.slice().sort((a, b) => parseInt(a.value) - parseInt(b.value))
    const maxValue = images.length
    
    const lineChart = new Chart(ctx, {
        type: 'customLine',
        data: {
            datasets: [{
                labels: labels,
                data: data,
                fill: false,
                tension: 0.5,
                borderColor: borderColour || '#ffa1a1',
                borderWidth: 2,
                pointBackgroundColor: borderColour || '#ffa1a1',
                pointBorderWidth: 1,
                pointRadius: 4,
                pointHoverRadius: 5
            }]
        },
        plugins: [{
            afterDraw: chart => {
                const ctx = chart.ctx
                const yAxis = chart.scales['y']
                yAxis.ticks.forEach((value, index) => {
                    const y = yAxis.getPixelForTick(index)
                    const image = new Image()
                    image.src = imageArr[index].image
                    ctx.drawImage(image, 0, y - 20, 35, 35)
                })
            }
        }],
        options: {
            scales: {
                x: {
                    type: 'time',
                    time: {
                        unit: 'day',
                        displayFormats: {
                            day: 'dd MMM'
                        }
                    },
                    ticks: {
                        source: 'auto'
                    },
                    grid: {
                        display: false
                    }
                },
                y: {
                    beginAtZero: false,
                    suggestedMin: 1,
                    suggestedMax: maxValue,
                    grid: {
                        zeroLineWidth: 0,
                        zeroLineColor: 'rgba(0, 0, 0, 0.1)'
                    },
                    ticks: {
                        precision: 0,
                        padding: 20,
                        color: '#fff'
                    },
                    offset: 0
                }
            },
            interaction: {
                mode: 'index',
                intersect: false,
            },
            layout: {
                padding: {
                    left: 0,
                    right: 4,
                    top: 4,
                    bottom: 0
                }
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: true,
                    mode: 'x',
                    axis: 'x',
                    displayColors: false,
                    callbacks: {
                        title: (context) => {
                            return new Date(context[0].raw.x).toDateString()
                        },
                        label: (context) => {
                            return [`Total Ratings: ${context.raw.totalVotes}`, `Average Rating: ${context.raw.y}`]
                        }
                    }
                },
                title: {
                    display: true,
                    text: ''
                }
            },
            spanGaps: true,
            responsive: true
        }
    })

    return lineChart
}

const updateChart = async (ctx, dataSet) => {
    const { labels, data } = dataSet
    ctx.data.datasets[0].data = data
    ctx.data.labels = labels
    ctx.update()
}

export { drawBarChart, drawLineChart, updateChart }