<template>
  <select v-if="isReport" class="filter-select" v-model="chartDays" :style="{ 'border': `1px solid ${widget.settings.mainColour}`, 'background-color': widget.settings.bgColour }">
    <option value="7">Last 7 days</option>
    <option value="30">Last 30 days</option>
    <option value="90">Last 90 days</option>
    <option value="180">Last 180 days</option>
  </select>
  <select v-else class="filter-select" v-model="chartDays">
    <option value="7">Last 7 days</option>
    <option value="30">Last 30 days</option>
    <option value="90">Last 90 days</option>
    <option value="180">Last 180 days</option>
  </select>
  <div class="charts">
    <div class="bar-chart" :class="{ 'hidden-chart': isLoading }">
      <canvas ref="barChart" id="barChart" width="460" height="260"></canvas>
    </div>
    <span v-if="isLoading" class="loading-charts">Loading...</span>
    <div class="line-chart" :class="{ 'hidden-chart': isLoading }">
      <canvas ref="lineChart" id="lineChart" width="460" height="260"></canvas>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted, toRefs } from 'vue'
import useFetch from '../../composables/useFetch'
import { drawBarChart, drawLineChart, updateChart } from '../../composables/useCharts'

export default {
  props: ['widget', 'isReport'],
  setup(props) {
    const { getData, error, isLoading } = useFetch()
    const { widget } = toRefs(props)
    const chartDays = ref('7')
    const barChart = ref(null)
    const lineChart = ref(null)
    let barCharter, lineCharter

    const images = computed(() => {
      let arr = []

      if (widget.value) {
        Object.entries(widget.value.emojis).forEach(([key, value]) => {
          arr.push({ 
            image: value.emojiURL,
            value: value.value 
          })
        })
      }

      return arr
    })

    onMounted(async () => {
      if (widget.value && barChart.value && lineChart.value) {
        const data = await getData(
          `/widgets/${widget.value.id}/chart`,
          `days=${chartDays.value}&from=${Math.floor((new Date().setHours(0, 0, 0, 0) - (8.64e7 * (parseInt(chartDays.value) - 1))))}&tzOffset=${new Date().getTimezoneOffset()}`,
          false
        )
        
        const { barData, lineData } = data

        barCharter = await drawBarChart(barChart.value, barData, images.value)
        lineCharter = await drawLineChart(lineChart.value, lineData, images.value)
      }
    })

    if (!props.isReport) {
      watch(widget, async () => {
        if (widget.value && barCharter && lineCharter) {
          const data = await getData(
            `/widgets/${widget.value.id}/chart`,
            `days=${chartDays.value}&from=${Math.floor((new Date().setHours(0, 0, 0, 0) - (8.64e7 * (parseInt(chartDays.value) - 1))))}&tzOffset=${new Date().getTimezoneOffset()}`
          )
          
          const { barData, lineData } = data
        
          updateChart(barCharter, barData)
          updateChart(lineCharter, lineData)
        }
      })
    }

    if (widget.value.stats.followUps > 0) {
      watch(chartDays, async () => {
        if (widget.value && barCharter && lineCharter) {  
          const data = await getData(
            `/widgets/${widget.value.id}/chart`,
            `days=${chartDays.value}&from=${Math.floor((new Date().setHours(0, 0, 0, 0) - (8.64e7 * (parseInt(chartDays.value) - 1))))}&tzOffset=${new Date().getTimezoneOffset()}`,
            false
          )
          
          const { barData, lineData } = data

          updateChart(barCharter, barData)
          updateChart(lineCharter, lineData)
        }
      })
    }
  
    return { chartDays, barChart, lineChart, isLoading }
  }
}
</script>

<style scoped>
  .hidden-chart {
    visibility: hidden;
  }
  .charts {
    text-align: center;
  }
  .filter-select {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 27px;
    margin-left: 10px;
    border: 1px solid var(--main);
    border-radius: 7px;
    padding: 0 5px;
    background-color: #fff0f0;
    cursor: pointer;
  }
</style>